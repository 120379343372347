import React, { useRef, useState, useEffect } from "react";
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import Api from "./api";
import { Image } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import HCaptcha from '@hcaptcha/react-hcaptcha'

function App() {

  const emailRef = useRef(null)
  const api = new Api()
  const [data, setData] = useState(null)
  const [token, setToken] = useState(null);
  const sitekey = "27a41f22-592e-4262-ad4a-5b4afc3e7dc8"

  useEffect(() => {
    if (token)
      console.log(`hCaptcha Token: ${token}`);
  }, [token]);

  const handleSubmit = async event => {
    event.preventDefault()
    const subscibeData = {"email": emailRef.current.value}
    await api.postSubscribe(subscibeData).then((res) => {
      setData(res.data)
      console.log(res.data)
    }).catch((err)=> {
      console.error(err)
    })
    // console.log(emailRef.current.value)
  }
  
  const baseURL = window.location.origin
  return (
    <>
      <Helmet>
        <title>QRLYF.com | Free and generate unlimited QR Code</title>
        <link rel="canonical" href={baseURL} />
        <meta name="description" content="QRLYF.com is the ultimate QR code generator! With our easy-to-use platform, you can create QR codes for any purpose."/>
      </Helmet>
      <div className="px-3 py-3 my-2 text-center">
        <h1>QRLYF.com are coming soon!</h1>
      <Image className="d-block mx-auto mb-4" src="/qrlyf-logo.png" alt="qrlyf.com logo" width="200" height="200" />
      <h2 className="fw-bold">QRLYF.com</h2>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">
        QRLYF.com is the ultimate QR code generator! With our easy-to-use platform, you can create QR codes for any purpose – from sharing files to checking restaurant menus to tracking attendance at events. Plus, our QR codes are compatible with all major smartphones and devices, so you can use them anywhere, anytime.
        </p>
        {data &&
        <Alert variant="dark">
          {data.message}
        </Alert>}
        <p><small>We are almost there! If you want to get notified when the website goes live subscribe to our mailing list!</small></p>

        <Form onSubmit={handleSubmit}>
          <InputGroup className="mb-2">
            <Form.Control
              required
              type="email"
              placeholder="Enter your email"
              aria-label="Enter your email"
              aria-describedby="Enter your email"
              ref={emailRef}
            />
            <Button variant="dark" type="submit">
              Sign Up
            </Button>
          </InputGroup>
          <HCaptcha
            sitekey={sitekey}
            onVerify={setToken}
            onExpire={() => setToken(null)}
          />
        </Form>
        <p className="lead text-muted">‘QR Code’ is a registered trademark of DENSO WAVE.</p>
      </div>
      </div>
    </>
  );
}

export default App;
